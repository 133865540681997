import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import SEO from '../components/seo'
// import { rhythm } from '../utils/typography'
import styled from 'styled-components'
import { COL_GREEN, MQ_MEDIUM_UP } from '../utils/cssvars'

// const CaseStudy = styled(Link)``

const GridContainer = styled.div`
  align-items: flex-start;
  display: flex;
  justify-content: center;
  width: 100%;
`

const Grid = styled.div`
  display: grid;
  grid-gap: 3rem;

  @media (${MQ_MEDIUM_UP}) {
    /* grid-template: auto / repeat(2, 1fr); */
    grid-template: auto / auto 30%;
  }
`

const GridItem = styled.div`
  /* align-self: start; */
`

const GridTitle = styled.div`
  color: ${COL_GREEN};
  padding: 00;
  margin: 0 0 1rem;
`

class BlogIndex extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const caseStudies = data.allMarkdownRemark.edges.filter(item =>
      /\/casestudies\//.test(item.node.fileAbsolutePath)
    )
    // const posts = data.allMarkdownRemark.edges.filter(item =>
    //   /\/blog\//.test(item.node.fileAbsolutePath)
    // )

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title="Home"
          keywords={[`blog`, `gatsby`, `javascript`, `react`]}
        />
        <GridContainer>
          <Grid>
            <GridItem>
              <GridTitle>About me</GridTitle>
              <p>Hi!</p>
              <p>
                I am a highly experienced web developer based in the South West
                of England.
              </p>
              <p>
                I have extensive experience of all things frontend, HTML, CSS my
                main focus is modern JavaScript and TypeScript with my most
                recent work being React based. I also have a keen eye for
                animation which developed during my 10 years as a Flash
                developer.
              </p>
              <p>
                I'm at home working in all sorts of teams from small to large,
                agile or not, and enjoy solving problems together with others.
              </p>
            </GridItem>
            <GridItem>
              <GridTitle>Latest projects</GridTitle>
              {caseStudies.map(({ node }) => (
                <>
                  <a
                    href={node.frontmatter.siteUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {node.frontmatter.title}
                  </a>
                  <br />
                </>
              ))}
              {/* return (
              <CaseStudy
                className="clean"
                to={`/${node.fields.slug}`}
                key={node.fields.slug}
              >
                <img
                  src={`/assets/${node.frontmatter.images[0]}`}
                  alt={node.frontmatter.title}
                />
                {node.frontmatter.title}
              </CaseStudy>
              ) } */}
              <GridTitle style={{ marginTop: '2rem' }}>Links</GridTitle>
              <a
                href="https://gist.github.com/pixelbreaker/55906a6948391300b2c75475b88502fe"
                target="_blank"
                rel="noopener noreferrer"
              >
                Latest Resumé
              </a>
              <br />
              <a
                href="https://www.linkedin.com/in/gabes-mak-4b431418a/"
                target="_blank"
                rel="noopener noreferrer"
              >
                LinkedIn
              </a>
              <br />
              <a
                href="https://github.com/pixelbreaker/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Github
              </a>
            </GridItem>
            {/* <GridItem>
              {posts.map(({ node }) => {
                const title = node.frontmatter.title || node.fields.slug
                return (
                  <div key={node.fields.slug}>
                    <h3
                      style={{
                        marginBottom: rhythm(1 / 4),
                      }}
                    >
                      <Link
                        style={{ boxShadow: `none` }}
                        to={`/${node.fields.slug}`}
                      >
                        {title}
                      </Link>
                    </h3>
                    <small>{node.frontmatter.date}</small>
                    <p dangerouslySetInnerHTML={{ __html: node.excerpt }} />
                  </div>
                )
              })}
            </GridItem> */}
          </Grid>
        </GridContainer>
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fileAbsolutePath
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            siteUrl
            images
          }
        }
      }
    }
  }
`
